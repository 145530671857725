import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClienteList(credorId) {
  // Use toast
  const toast = useToast()

  const refClienteListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'nome' },
    { key: 'codigo' },
    { key: 'tipo_pessoa' },
    { key: 'documento' },
    // { key: 'email' },
    // { key: 'telefone' },
    { key: 'situacao' },
    { key: 'credor' },
    { key: 'actions', label: "Ações" },
  ]
  const perPage = ref(15)
  const currentPage = ref(1)
  const perPageOptions = [10, 15, 25, 50, 100]
  const searchQuery = ref('')
  const situacaoFilter = ref(null)
  const tipoPessoaFilter = ref(null)
  // const credorId = ref(route.params.credorId)

  const dataMeta = ref({})

  const refetchData = () => {
    refClienteListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situacaoFilter, tipoPessoaFilter], () => {
    refetchData()
  })

  const fetchClientes = (ctx, callback) => {
    store
      .dispatch('app-cliente/fetchClientes', {
        busca: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        situacao: situacaoFilter.value,
        tipo_pessoa: tipoPessoaFilter.value,
        credor_id: credorId.value
      })
      .then(response => {
        const { data, meta } = response.data     
        callback(data)
        dataMeta.value = meta
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching cliente list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveClienteStatusVariant = status => {
    if (status === 'ATIVO') return 'success'
    if (status === 'INATIVO') return 'danger'
    return 'primary'
  }

  return {
    fetchClientes,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    refClienteListTable,

    resolveClienteStatusVariant,
    refetchData,

    // Extra Filters
    situacaoFilter,
    tipoPessoaFilter
  }
}
